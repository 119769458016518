import * as React from 'react';
import 'semantic-ui-css/semantic.min.css';
import '../../sass/main.scss';
import './index.scss';
import mestekLogo from './../../images/mestek-logo.svg';
import favicon from './../../images/favicon.png';

const benefits = [
	{
		Benefit: 'Medical Plan - High Deductible Health Plan (HDHP)',
		Summary:
			'Hospital, medical and surgical benefits provided under Preferred Provider Organization  (PPO).  Premium costs vary based on years of service.'
	},
	{
		Benefit: 'Prescription Coverage',
		Summary:
			'All covered prescription drugs are included as part of medical plan and subject to annual deductible and co-insurance.'
	},
	{
		Benefit: 'Opt-Out Bonus',
		Summary:
			'Opt-out bonus of $1,500 per year paid quarterly if not enrolled in our medical insurance.  Higher bonus amounts awarded as service years grow.'
	},
	{
		Benefit: 'Health Savings Account (HSA)',
		Summary:
			'If enrolled in our medical insurance then HSA is interest-bearing savings account to which pre-tax dollars can be contributed to help pay deductible, co-insurance, and any medical expenses not covered by medical insurance.  20% Company match on employee contributions.  Company paid wellness credits available for completing preventative care actions.'
	},
	{
		Benefit: 'Flexible Spending Account (FSA)',
		Summary:
			'Account to which pre-tax dollars can be contributed to help pay the deductible, co-insurance, and any expenses not covered by medical, dental, or vision insurance OR to help pay dependent care expenses such as day care.'
	},
	{
		Benefit: 'Dental Plan',
		Summary:
			'Voluntary 50% Company / 50% employee paid dental insurance.  $50 deductible with preventative care covered at 100%.'
	},
	{
		Benefit: 'Vision Insurance Plan',
		Summary:
			'Voluntary 100% employee paid vision insurance. $10 deductible with one eye exam covered at 100% per year.  Also provides annual allowance for lenses and frames OR for contact lenses.'
	},
	{
		Benefit: 'Short Term Disability (STD)',
		Summary:
			"100% Company paid disability insurance.  Provides 60% of gross base pay, maximum $1,000 per week, for up to 26 weeks for employee's own illness or injury."
	},
	{
		Benefit: 'Long Term Disability (LTD)',
		Summary:
			'Voluntary 100% employee paid disability insurance.  Provides 60% of gross base monthly earnings, maximum $10,000 per month, for up to 2 years or until age 65 based on annual base salary.'
	},
	{
		Benefit: 'Family Medical Leave Act (FMLA)',
		Summary: 'Federally mandated program available for eligible employees.'
	},
	{
		Benefit: 'Mestek. Inc. Wellness Program',
		Summary:
			'On-site Wellness Center (Westfield) and fitness program offerings.  $150 per year fitness and weight loss reimbursements also available through medical insurance plan.'
	},
	{
		Benefit: 'Wellness Program Flex Time',
		Summary:
			'Up to 1 hour (60 minutes) of “flex time” each week to exercise or attend various wellness program opportunities.'
	},
	{
		Benefit: 'Life Insurance',
		Summary:
			"100% Company paid life insurance.  One (1) times employee's base salary rounded to next higher multiple of $500."
	},
	{
		Benefit: 'Accidental Death & Dismemberment',
		Summary:
			"100% Company paid accidental death and dismemberment insurance.  Death benefit one (1) times employee's base salary rounded to  next higher multiple of $500, dismemberment benefit paid according to dismemberment schedule."
	},
	{
		Benefit: 'Supplemental Group Term Life Insurance',
		Summary:
			'Voluntary 100% employee paid supplemental life insurance for employee, spouse, and/or children.  Premium rates based on age and coverage amount.'
	},
	{
		Benefit: 'Mestek, Inc. 401(k)',
		Summary:
			'Allows for pre-tax earnings to be contributed to retirement investment program.  Between 1% and 50% of pre-tax compensation can be contributed to the Plan.  Company match $0.33 on the dollar of contributions up to 6% and matching funds are subject to vesting schedule.'
	},
	{
		Benefit: 'Mestek, Inc. Profit Sharing Plan',
		Summary:
			'Program that enables employees to share in growth and profits of the Company and provides opportunity for additional accumulation of funds for retirement.  Company determines annually amount of  contribution to plan and contributions are subject to vesting schedule.'
	},
	{
		Benefit: 'Vacation',
		Summary:
			'Accrual based on years of service using 12 month period beginning on and determined as of June 1 of each year.'
	},
	{
		Benefit: 'Holidays',
		Summary: '11 each year'
	},
	{
		Benefit: 'Bereavement Pay',
		Summary:
			'After 30 days of service, in event of death in immediate family, allowed up to 3 consecutive calendar days with full pay for any scheduled workday, Monday through Friday.'
	},
	{
		Benefit: 'Jury Duty',
		Summary:
			'Company will make up difference between compensation received for Jury Duty and normal workday.'
	},
	{
		Benefit: 'Military Leave',
		Summary:
			'Company will make up difference between military pay and straight time pay during annual active duty training for a maximum of 2 weeks per calendar year.'
	},
	{
		Benefit: 'Time Off for Blood Donations',
		Summary:
			'Time off with pay to donate blood when bloodmobile visits the Company’s facility.'
	},
	{
		Benefit: 'Tuition Reimbursement Program',
		Summary:
			'Regular, full-time employees with at least 6 months of service may apply for reimbursement.  Undergraduate courses limited to $3,000 per year.  Graduate courses limited to $5,000 per year.'
	}
];

import Layout from '../../layouts/layout/Layout';
import { createMedia } from '@artsy/fresnel';
import { PageProps } from 'gatsby';

import heart from '../../images/heart.svg';
import bulb from '../../images/bulb.svg';
import people from '../../images/people.svg';

import buildings from '../../images/buildings.svg';
import globe from '../../images/globe.svg';
import worker from '../../images/worker.svg';

import city from '../../images/city.png';
import { Accordion, Icon } from 'semantic-ui-react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import JobAlerts from '../../components/JobAlerts/JobAlerts';

const BenefitsPage: React.FC<PageProps> = () => {
	const menuItems = [
		{ name: 'Home', href: '/' },
		{ name: 'Job Opportunities', href: '/search' },
		{ name: 'Benefits', href: '/benefits' }
	];
	const { MediaContextProvider, Media } = createMedia({
		breakpoints: {
			mobile: 0,
			tablet: 900,
			computer: 1240
		}
	});
	const [activeIndex, setActiveIndex] = useState(0);

	const handleClick = (e: any, titleProps: any) => {
		const index = titleProps.index;
		console.log('titleProps:', titleProps);
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};
	return (
		<Layout
			media={Media}
			mediaContextProvider={MediaContextProvider}
			logoSrc={mestekLogo}
			menuItems={menuItems}
			title={'Mestek Careers'}
			desc={
				'Explore job opportunities at Mestek, Inc., a family of manufacturers of HVAC equipment and automated manufacturing machinery with a legacy of innovation.'
			}
		>
			<Helmet>
				<title>{'Mestek Careers'}</title>
				<meta
					name="description"
					content={
						'Explore job opportunities at Mestek, Inc., a family of manufacturers of HVAC equipment and automated manufacturing machinery with a legacy of innovation.'
					}
				/>
				<link
					rel="icon"
					type="image/png"
					href={favicon}
					sizes="32x32"
				/>
			</Helmet>
			<div className={'small-hero py6'}>
				<div className={'container-xxl'}>
					<h1>Working At Mestek</h1>
					<h2>Experience Based Innovation</h2>
				</div>
			</div>
			<div className={'container-lg content py6 c jc ac'}>
				<h2 className={'tac'}>Employment with Mestek</h2>
				<div
					className={
						'benefit-icons mt5  tac w100 r-c-ml ac-ml jsb afs'
					}
				>
					<div className={'c ac icon mb5-ml'}>
						<img height={70} src={heart} alt={'Heart icon'} />
						<h3>Diverse and dynamic workforce</h3>
					</div>
					<div className={'c ac icon mb5-ml'}>
						<img height={70} src={bulb} alt={'Bulb icon'} />
						<h3>Innovation and creativity</h3>
					</div>
					<div className={'c ac icon'}>
						<img height={70} src={people} alt={'Friendship Icon'} />
						<h3>Respectful, family-friendly atmosphere</h3>
					</div>
				</div>
			</div>
			<div className={'container-xxl'}>
				<h3>Industries</h3>
				<p>
					Mestek, Inc continues to expand its legacy, concentrating in
					the hydronic heating segments, as well as in other
					concentrated areas such as gas-fired air side heating
					equipment, metal forming, and architectural, including
					louvers and dampers.
				</p>
			</div>
			<div className={'container-xxl mt5'}>
				<div className={'r-c-ml jc ac cubes'}>
					<div className={'c jc ac w33 w100-ml cube cube1'}>
						<img src={buildings} alt={'Buildings icon'} />
						<span className={'mt4'}>12</span>
						<span>States</span>
					</div>
					<div className={'c jc ac w33 w100-ml cube cube2'}>
						<img src={globe} alt={'Globe icon'} />
						<span className={'mt4'}>Over 75</span>
						<span>Locations</span>
					</div>
					<div className={'c jc ac w33 w100-ml cube cube3'}>
						<img src={worker} alt={'Worker icon'} />
						<span className={'mt4'}>2000+</span>
						<span>Employees</span>
					</div>
				</div>
			</div>

			<div className={'container-xxl py6 quality'}>
				<div className={'r-cr-xl ac jc'}>
					<img src={city} width={600} alt={'City skyline'} />
					<div className={'c jc pl5 pl0-xl mb5-xl'}>
						<h3>Superior quality through continuous innovation</h3>
						<p>
							As a premier engineering and applications company
							with expertise in a multitude of product categories,
							our esteemed brands provide better-engineered
							solutions to our customers. With the continuous
							improvement of our engineering and manufacturing
							processes, we are devoted to providing our customers
							with the highest quality they’ve come to expect from
							our brands.
						</p>
					</div>
				</div>
			</div>

			<div className={'container-xxl benefits-list pb6'}>
				<h2>Mestek Benefits</h2>
				<Accordion styled style={{ width: '100%' }}>
					{benefits.map((benefit, i) => {
						return (
							<>
								<Accordion.Title
									active={activeIndex === i}
									index={i}
									onClick={handleClick}
								>
									<Icon name="dropdown" />
									<p className={'ben-title'}>
										{benefit?.Benefit}
									</p>
								</Accordion.Title>
								<Accordion.Content active={activeIndex === i}>
									<p className={'ml4'}>{benefit?.Summary}</p>
								</Accordion.Content>
							</>
						);
					})}
				</Accordion>
			</div>

			<JobAlerts />
		</Layout>
	);
};
export default BenefitsPage;
